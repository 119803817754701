import React, { useContext, useEffect, useState } from "react";
import "../css/sections.scss";
import { FirstPageContent } from "../content/FirstPageContent";
import { AppContext } from "../context/AppContext";
import { status } from "../enum/userStatus";

import ConfirmContent from "../content/ConfirmContent";
import Message from "./Message";
import RejectContent from "../content/RejectContent";

const SectionList = {
  [status.notGoing]: () => (
    <RejectContent>
      Ah que pena que não teremos você conosco! Fique bem.
    </RejectContent>
  ),
  [status.going]: ({ data, interactionCallback, show }) => (
    <ConfirmContent
      show={show}
      data={data}
      interactionCallback={interactionCallback}
    />
  ),
  [status.active]: ({ data, interactionCallback, show }) => (
    <FirstPageContent
      show={show}
      data={data}
      interactionCallback={interactionCallback}
    />
  ),
  [status.expired]: () => (
    <Message>
      Olá, seu link de confirmação de presença para festa do Be já passou da
      data e expirou. Caso tenha pensado melhor e ainda queira comparecer mande
      uma mensagem para Rumenigue ou Nathalia para verificar se ainda há
      disponilibidade.
    </Message>
  ),
  [status.accepted]: () => (
    <Message alignCenter>
      Olá, você já confirmou presença no aniversário do Be. Anote na agenda: 02
      de Outubro, exatamente ao meio dia.
    </Message>
  ),
  [status.justAccepted]: () => (
    <Message alignCenter>
      Sua presença no aniversário do Be foi confirmada! <br />
      Anote na agenda: 02 de Outubro, exatamente ao meio dia.
      <br />
      <br />
      Já pode fechar essa página.
    </Message>
  ),
  [status.rejected]: () => (
    <Message>
      Mudou de ideia? Caso queira comparecer no aniversário do dinossaurinho Be,
      mande mensagem para Rumenigue ou Nathalia para verificar se ainda há
      disponilibidade.
    </Message>
  ),
  [status.error]: () => (
    <Message>
      Parece que os dinossauros foram extintos e o link que você acessou é
      inválido.
    </Message>
  ),
};

function Sections({ showThis }) {
  const { userData } = useContext(AppContext);

  const [currentPage, setCurrentPage] = useState(status.active);

  useEffect(() => {
    if (!userData || Object.keys(userData).length <= 0) {
      setCurrentPage(status.error);
      return;
    }
    if (!userData.status !== status.active && userData.status !== 404) {
      setCurrentPage(userData.status);
    }
    if (userData.status === 404) {
      setCurrentPage(status.error);
    }
  }, [userData]);

  const CurrentComponent = SectionList[currentPage];

  return (
    <div className="sections-container">
      <section className={`first-page-content show`}>
        <CurrentComponent
          show={showThis}
          data={userData}
          interactionCallback={(nextPage) => {
            setCurrentPage(nextPage);
          }}
        />
      </section>
    </div>
  );
}

export default Sections;
