import "./css/app.scss";
import "@fontsource/clear-sans";
import React, { useState } from "react";
import { useKonami } from "react-konami-code";
import Sections from "./components/Sections";
import VideoIntro from "./components/VideoIntro";
import { AppProvider } from "./context/AppContext";
import Dino from "./components/Dino";

function App() {
  const [showBackground, setShowBackground] = useState(true);
  const [aa, setAa] = useState(false);
  const easterEgg = () => {
    setAa(true);
  };
  useKonami(easterEgg);

  const showThis = showBackground ? "show" : "";

  return (
    <AppProvider>
      {aa ? (
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h1>PARABÉÉÉÉÉNS BEEEEERRNARRRDO</h1>
            <h2>Aperte "espaço" para começar</h2>
            <br />
            <br />
            <Dino />
          </div>
        </div>
      ) : (
        <>
          {/* <div
            className={`App video-container ${
              showBackground ? "hideVideo" : ""
            }`}
          >
            <VideoIntro
              onVideoHide={(isHidden) => setShowBackground(isHidden)}
            />
          </div> */}
          <div className={`background ${showThis}`}>
            <Sections showThis={showThis} />
          </div>
        </>
      )}
    </AppProvider>
  );
}

export default App;
