export const status = {
  going: "Going",
  notGoing: "NotGoing",
  error: "Error",
  justAccepted: "JustAccepted",

  active: "Active",
  expired: "Expired",
  accepted: "Accepted",
  rejected: "Rejected",
};
