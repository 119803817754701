export const status = {
  error: "Error",
  processing: "Processing",
  success: "Success",
};

export default class PostHandle {
  dataToSend = { guest: {}, companions: [] };

  status = status.processing;

  setError() {
    this.status = status.error;
    return this;
  }

  processData = (formdata, counter) => {
    const nameUser = formdata.get("Nome0");
    const ageUser = Number(formdata.get("Idade0"));

    if (!nameUser || !ageUser) {
      return this.setError();
    }

    this.dataToSend.guest = { name: nameUser, age: ageUser };

    for (let index = 0; index < counter; index++) {
      const name = formdata.get(`NomeAcompanhante${index}`);
      const age = Number(formdata.get(`IdadeAcompanhante${index}`));
      if (name && age) {
        this.dataToSend.companions.push({ name, age });
      }
    }
    this.status = status.success;
    return this;
  };

  onSubmit = (event) => {
    event.preventDefault();
    return this;
  };

  send = (callback) => {
    callback(this.dataToSend, this.status);
  };
}
