import React, { useEffect, useRef, useState } from "react";
import Modal from "simple-react-modal";
import { useContext } from "react";
import Button from "../components/Button";
import { AppContext } from "../context/AppContext";
import "../css/form.scss";
import PostHandle, { status as processStatus } from "../functions/postHandle";
import { status as userStatus } from "../enum/userStatus";

const InputComponent = ({
  posfix = "",
  inputTextLabel,
  inputNumberLabel,
  textStyle = {},
  numberStyle = {},
  index = 0,
}) => {
  return (
    <>
      <div>
        <input
          type="text"
          name={inputTextLabel + posfix + index}
          className="form__field"
          placeholder={inputTextLabel}
          style={{ ...textStyle }}
        />
        <label className="form__label" htmlFor="name">
          {inputTextLabel}
        </label>
      </div>
      <div>
        <input
          type="number"
          name={inputNumberLabel + posfix + index}
          className="form__field"
          placeholder={inputNumberLabel}
          style={{ ...numberStyle }}
        />
        <label className="form__label" style={{ ...numberStyle }}>
          {inputNumberLabel}
        </label>
      </div>
    </>
  );
};

const SimpleModal = ({ show: showModal, onClose }) => {
  const [show, setShow] = useState(false);
  const close = () => {
    setShow(false);
    onClose(false);
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <Modal
      show={show}
      onClose={close}
      style={{ display: "flex", alignItems: "center" }}
      containerStyle={{
        display: "flex",
        alignItems: "center",
        background: "green",
        height: "80px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        Por favor insira os seus dados antes de enviar a confirmação!
      </div>
    </Modal>
  );
};

function ConfirmContent({ data, interactionCallback }) {
  const [showModal, setShowModal] = useState(false);
  const { userData, apiList } = useContext(AppContext);

  const ref = useRef();

  if (!data) return null;

  const { companionCount, name } = data;

  const compationCountList = Array.from(Array(data.companionCount).keys());

  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(ref.current);
    new PostHandle()
      .onSubmit(event)
      .processData(formData, data.companionCount)
      .send((data, s) => {
        if (s === processStatus.error) {
          setShowModal(true);
          return;
        }
        apiList.postAccept(userData.id, data).then(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          interactionCallback(userStatus.justAccepted);
        });
      });
  };

  return (
    <>
      <div className={`content show`}>
        <h2>OLÁ {name}!</h2>

        <form
          className="form__group"
          ref={ref}
          id="form"
          onSubmit={(event) => onSubmit(event)}
        >
          <fieldset className="about-you-section">
            <legend>Insira abaixo seus dados</legend>
            <InputComponent inputTextLabel="Nome" inputNumberLabel="Idade" />
          </fieldset>

          {companionCount > 0 && (
            <>
              <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                Insira abaixo os dados de seus acompanhantes
              </div>
              <div className="companion-section">
                {compationCountList.map((_, i) => {
                  return (
                    <fieldset key={i} className="companion-list">
                      <legend>{`Acompanhante ${i + 1}`}</legend>
                      <InputComponent
                        posfix="Acompanhante"
                        inputTextLabel="Nome"
                        inputNumberLabel="Idade"
                        numberStyle={{ width: "50px", textAlign: "end" }}
                        index={i}
                      />
                    </fieldset>
                  );
                })}
              </div>
            </>
          )}
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              textAlign: "center",
            }}
          >
            Obrigada por inserir seus dados. Para nos vermos no dia 02, clique no botão abaixo e confirme sua presença.
          </div>
        </form>

        <div className="button-group">
          <Button form="form" type="submit" onClick={() => {}}>
            ENVIAR CONFIRMAÇÃO
          </Button>
        </div>
        <SimpleModal
          show={showModal}
          onClose={(status) => setShowModal(status)}
        />
      </div>
    </>
  );
}

export default ConfirmContent;
