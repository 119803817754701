import React from "react";
import "../css/message.scss";

function Message({ children }) {
  return (
    <p className="section-message" style={{ textAlign: "center" }}>
      {children}
    </p>
  );
}

export default Message;
