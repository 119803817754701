import React from "react";
import Button from "../components/Button";
import Content from "../components/Content";
import { status } from "../enum/userStatus";

export const FirstPageContent = ({ interactionCallback, data, show }) => (
  <Content showThis={show} guestName={data.name}>
    <p>
      Você foi convidado para o aniversário de 1 ano do dinossaurinho{" "}
      <span> Bernardo</span> e será um prazer ter você conosco nesta comemoração
      tão especial! A festa será realizada no Buffet Villa do Sol em Osasco
      exatamente ao meio dia, então não se atrase para que possa curtir conosco
      todos os momentos, lembrando que os papais do Be não moram mais no Brasil
      então pedimos atenção aos seguintes pontos:
      <br></br>- Caso queira dar um presentinho para o Be, opte por roupas ou
      calçados para que seja fácil de carregar na mala. No momento o Be usa
      tamanho 1 ou 2 de roupas e usa tamanho 20/21 de calçado.
      <br></br>- Caso prefira dar um presente em dinheiro, poderá realizar o pix
      para o número 354.513.458-05.
      <br></br>
      <br></br>A seguir, você poderá confirmar sua presença e caso tenha
      acompanhantes poderá fazer a inclusão deles também, não esqueça de inserir
      nome completo e idade. Pedimos a gentileza, caso não possa comparecer,
      clique no botão: Não poderei comparecer.
      <br></br>
      <br></br>
      Obrigada!
    </p>
    <div className="button-group">
      <Button
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          interactionCallback(status.going);
        }}
      >
        CONFIRMAR PRESENÇA
      </Button>
      <Button
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          interactionCallback(status.notGoing);
        }}
      >
        NÃO PODEREI COMPARECER
      </Button>
    </div>
  </Content>
);
