import React, { useContext, useEffect } from "react";
import Message from "../components/Message";
import { AppContext } from "../context/AppContext";

function RejectContent({ children }) {
  const { userData, apiList } = useContext(AppContext);

  useEffect(() => {
    if (userData && apiList) {
      apiList.postReject(userData.id);
    }
  }, [apiList, userData]);

  return <Message>{children}</Message>;
}

export default RejectContent;
