import React from "react";
import "../css/button.scss";

function Button({ children, onClick, ...props }) {
  return (
    <button type="button" className="button-46" onClick={onClick} {...props}>
      {children}
    </button>
  );
}

export default Button;
