import React, { useEffect, useRef } from "react";

function Content({ showThis, guestName, children }) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  });

  return (
    <div className={`content ${showThis}`}>
      <h2>OLÁ {guestName}!</h2>
      <div className="content-item" ref={ref}>
        {children}
      </div>
    </div>
  );
}

export default Content;
