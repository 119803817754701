import PropTypes from "prop-types";
import React, { createContext, useEffect } from "react";
import { get, rejected, accept } from "../api/api";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [userData, setUserData] = React.useState({});
  const [urlParam, setUrlParam] = React.useState("");
  const [apiList] = React.useState({
    postReject: rejected,
    postAccept: accept,
  });

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const id = params.get("id");
    id && setUrlParam(id);
  }, []);

  useEffect(() => {
    const getData = async (id) => {
      const response = await get(id);
      const data = await response.json();
      setUserData(data);
    };
    urlParam && getData(urlParam);
  }, [urlParam]);

  const value = React.useMemo(
    () => ({ userData, apiList }),
    [userData, apiList]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
