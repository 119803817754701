import apiJson from "./url.json";

let headers = new Headers();
headers.append("content-type", "application/json");
headers.append("access-control-allow-origin", "*");

export const get = async (id) => {
  return fetch(`${apiJson.url}${id}`);
};

export const rejected = async (id) => {
  return fetch(`${apiJson.url}${id}/reject`, { method: "POST" });
};

export const accept = async (id, data) => {
  return fetch(`${apiJson.url}${id}/accept`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
};
